.Wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Icon {
    /*display: inline-block;
    width: 10px;*/
    margin-top: 7px;
}

.Block {
    display: inline-block;
    /*padding: 3px;*/
    /*border: 1px solid #8F5C2C;*/
}