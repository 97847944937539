.Button {
  color: #7c8796;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  padding: 5px;
  min-width: 50px;
  text-align: center;
}

.ButtonSmall {
  font-size: 15px;
}

.ButtonSubmit {
  color: #8dc153;
}

.ButtonDisabled {
  opacity: 0.5;
}

.ButtonRed {
  background-color: #db4453;
  color: white;
}
