@import "./../../../variables.module.css";

.Wrap {
  position: relative;
}

.Dropdown {
  position: absolute;
  top: 5px;
  right: 30px;
}

.Select {
  border: 1px solid var(--grey-500);
  background-color: var(--white);
  height: 40px;
  margin-right: 27px;
  padding-left: 15px;
  padding-right: 0px;
  font-size: 15px;
  line-height: 40px;
}

.NativeSelect {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.FullWidth .Dropdown {
  right: 3px;
}
.FullWidth .Select {
  margin-right: 0;
}
