.Wrapper {
  display: block;
  box-sizing: border-box;
  margin: 20px;

  
  background-color: #ffffff;
  border-radius: 6px;
  padding: 14px 20px 20px 0px;

  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.Filters {
  padding: 0 0 40px 20px;
}