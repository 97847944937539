.Wrap {
    display: inline-block;
    position: relative;
}

.LeftIcon {
    display: inline-block;
    position: absolute;
    left: -40px;
    top: 7px;
}

.RightIcon {
    display: inline-block;
    position: absolute;
    right: -40px;
}

.Caption {
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
}