.Title {
    font-size: 18px;
    line-height: 23px;
    font-weight: bold;
    text-transform: uppercase;
}

.TitleSmall {
    line-height: 23px;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

.TitleSubTitle {
    font-size: 12px;
    line-height: 22px;
    font-weight: normal;
    text-align: center;
    text-transform: none;
}
