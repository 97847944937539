.Container {
  margin: 10px 10px 80px 10px;
  border-radius: 6px;
  padding: 15px 20px 20px 20px;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.3);
  background-color: #FFFFFF;
  box-sizing: border-box;
}

.Title {
  color: #DB4453;
  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  text-transform: uppercase;
}

.TitleBlue {
  color: var(--blue);
  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  text-transform: uppercase;
}

.Head {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 19px;
}

.NoteContainer {
  height: 100px;
  border: 1px solid #000000;
  border-radius: 3px;
  background-color: #F8F9FC;
}

.Notes {
  background: transparent;
  outline: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  color: #000000;
  font-size: 16px;
  padding: 10px;
}
