.PageContent, :global(.page-content) {
    overflow: auto;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    z-index: 1;

    background-color: #EEF1F7;
/*    max-width: 315px;
    margin: 0 auto;*/
}