.TrainingItem {
  display: block;
  box-sizing: border-box;
  margin: 20px 20px 20px 20px;

  
  background-color: #ffffff;
  border-radius: 6px;
  padding: 14px 20px 20px;

  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.LastOne {
  display: block;
  box-sizing: border-box;
  margin: 20px 20px 80px 20px;

  background-color: #ffffff;
  border-radius: 6px;
  padding: 14px 20px 20px;

  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
}

.Today {
  color:red
}

.Next {
  color: blue
}

.Row {
  display: flex;
  margin-top: 5px;
}

.Head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.Date {
  font-size: 15px;
  font-weight: bold;
  line-height: 23px;
  text-transform: uppercase;
}

.DateToday {
  font-size: 15px;
  font-weight: bold;
  line-height: 23px;
  color:red;
  text-transform: uppercase;
}

.Edit {
  color: #cacfd6;
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.Exercise {
  width: 40%;
  font-size: 12px;
  line-height: 22px;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ExerciseValues {
  display: flex;
  color: var(--grey);
  font-size: 15px;
  line-height: 22px;
}

.ExerciseItem {
  min-width: 12px;
  padding-left: 3px;
}

.NotesTitle {
  margin: 10px 0px;
  font-size: 18px;
}

.Notes {
  font-size: 16px;
  word-break: break-word;
  white-space: pre-wrap;
}

.Notes button {
  font-size: 15px;
  opacity: 0.5;
  border: none;
  padding: 1px 0;
  outline: none;
}

.ArrowMore {
  border: solid;
  border-width: 0px 1.5px 1.5px 0;
  display: inline-block;
  transform: rotate(-45deg);
  height: 6px;
  width: 6px;
  margin-bottom: 1px;
  margin-left: 5px;
  border-color: #000;
}

.ArrowLess {
  border: solid;
  border-width: 0px 1.5px 1.5px 0;
  display: inline-block;
  transform: rotate(-135deg);
  height: 8px;
  width: 8px;
  margin-left: 10px;
  margin-bottom: -2px;
  border-color: #000;
}
