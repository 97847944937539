.TodaySummary {
  width: 220px;
  margin: 0 auto;
}

.Title {
  color: #000000;
  font-size: 15px;
  font-weight: bold;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
}

.NoteTitle {
  color: var(--grey);
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  margin: 7px 15px 35px;
}

.NoteSubtitle {
  color: var(--grey);
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  margin: 30px 10px 20px;
}

.Summary {
  margin: 0px 15px 30px;
}

.Done {
  height: 50px;
  padding: 10px;
}

.Row {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
}

.ExerciseValues {
  display: flex;
  color: var(--grey);
  font-size: 11px;
  line-height: 22px;
}

.ExerciseItem {
  min-width: 12px;
  padding-left: 3px;
}

.Exercise {
  font-size: 12px;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  text-transform: uppercase;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ExerciseValues {
  color: var(--grey);
  font-size: 16px;
  line-height: 22px;
}

.Note {
  margin: 30px;
}

:global(.ReactModal__Overlay--after-open) {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

:global(.ReactModal__Content--after-open) {
  width: 73%;
  top: 30% !important;
}
