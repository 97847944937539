
.empty {
  height: 80px;
  background-color: var(--grey-400);
  box-shadow: 0 -0.5px 0 0 rgba(0,0,0,0.3);
}

.itemsWrapper {
  margin: 30px;
}
