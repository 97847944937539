@import "./../../../variables.module.css";

.ItemLabel {
  font-size: 12px;
  font-weight: bold;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.HiddenItemLabel {
  color: transparent;
}

.InputWrap {
  height: 40px;
}

.Suffix {
  margin-left: 5px;
  height: 20px;
  color: #707372;
  font-family: Pepi;
  text-transform: none;
  font-weight: lighter;
  font-size: 11px;
}
