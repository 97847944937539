@import './../../variables.module.css';

.Auth {
    text-align: left;
}

.Hello {
    font-size: 18px;
    font-weight: bold;
    line-height: 26px;
    margin: 30px;
    text-transform: uppercase;
}

.Check {
    font-size: 18px;
    font-weight: bold;
    line-height: 26px;
    margin: 30px 30px 10px 30px;
    text-transform: uppercase;
}

.PendingAuth {
    color: var(--grey);
    font-size: 15px;
    line-height: 22px;
    margin: 10px 30px 30px 30px;
}

.Resend {
    display: flex;
    align-items: center;
}

.TryAgain {
    color: red;
    padding-left: 6px;
}

.LoginButton {
    margin: 10px 30px;
}

.EmailInput {
    margin: 30px;
    margin-bottom: 10px;
}

.Error {
    font-size: 15px;
    margin: 10px 30px;
    color: var(--red);
}
