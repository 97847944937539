.Input {
    height: 40px;
    width: 60px;
    border: 1px solid #000000;
    border-radius: 3px;
    background-color: #F8F9FC;
}

.InputWide {
    /*width: 120px;*/
    width: 85px;
}

.InputFull {
    width: 100%;
}

.Input input {
    background: transparent;
    outline: none;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    text-align: center;

    color: #000000;
    font-size: 25px;
    font-weight: bold;
}

.Input input[type=number]::-webkit-inner-spin-button,
.Input input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.InputDisabled {
    background-color: #F8F9FC;
}

.Caption {
    margin-top: 2px;
    color: #707372;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
}