.Wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.Icon {
    margin: 12px 5px 0px 5px;
}

.Block {
    display: inline-block;
}

.pounds {
    width: 106px;
}

.plates {
    height: 40px;
    width: 92px;
    margin-left: 15px;
}
