
.Button {
    display: inline-block;
    box-sizing: border-box;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    width: 100%;
    height: 70px;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ButtonInput {
    color: var(--white);
    font-size: 5vw;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    line-height: 27px;
}

.Disabled {
    color: #ccc;
    background-color: #eef1f7 !important;
    cursor: not-allowed;
}
  
.Button:first-of-type {
    margin-left: 0;
    padding-left: 0;
}

.Button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Success {
    background-color: #8DC153;
}

.Red {
    background-color: #db4453;
}
