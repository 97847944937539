@import "./../../../variables.module.css";

.Default {
  font-size: 15px;
  line-height: 22px;
  border: 1px solid var(--grey-500);
  background-color: var(--grey-300);
  width: 100%;
  margin-right: 5px;
  height: 40px;
  padding: 7px 15px 10px 15px;
  box-sizing: border-box;
  border-radius: 0;
}

.Suffix {
  font-size: 14px;
  color: #7c8796;
  text-align: left;
  width: 25px;
}

.FlexContainer {
  display: flex;
  justify-content: stretch;
  align-items: center;
}
