@import "./../../variables.module.css";

.OuterWrap {
  margin: 30px;
}

.UserProfile {
  max-width: 90%;
  margin: 0 auto;
}

.Title {
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  text-transform: uppercase;
}

.SubTitle {
  color: var(--grey);
  font-size: 15px;
  line-height: 22px;
}

.WrapItem {
  margin-bottom: 21px;
}

.FlexContainerCols {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.FlexItem {
  margin-top: 20px;
  width: 100%;
  margin-right: 2px;
}

.FlexContainerRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.FlexContainer {
  display: flex;
  justify-content: stretch;
  align-items: center;
}

.InputDefault {
  font-size: 15px;
  line-height: 22px;
  border: 1px solid var(--grey-500);
  background-color: var(--grey-300);
  width: 100%;
  margin-right: 5px;
  height: 40px;
  padding: 7px 15px 10px 15px;
  box-sizing: border-box;
  border-radius: 0;
}
