
.full {
  height: 60px;
	background-color: #FFFFFF;
  box-shadow: inset 0 -1px 0 0 rgba(202,207,214,0.5), 0 -1px 0 0 rgba(202,207,214,0.5);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  opacity: 1;
  width: 100%;
  height: 100%;
}

.input::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

.label {
  color: var(--grey);
  font-size: 15px;
  line-height: 22px;
  margin-left: 30px;
}

.value {
  font-size: 15px;
  line-height: 22px;
  margin-right: 30px;
}

.inputBox {
  position: absolute;
  width: 100%;
  opacity: 0;
}
