.Navbar {
    height: 50px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15pt;
    box-sizing: border-box;
    z-index: 90;
    box-shadow: inset 0 -1px 0 0 #434A54;
}

.Box {
    display: flex;
    /*flex-grow: 1;*/
}