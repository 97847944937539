.title {
  font-size: 35px;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
  margin-top: 30px;
}

.subtitle {
  margin-top: 20px;
  margin-bottom: 30px;
  color: var(--grey);
  font-size: 15px;
  line-height: 22px;
  text-align: center;
}

.iconsWrapper {
  margin: 20px;
  text-align: center;
}

.empty {
  height: 80px;
  background-color: var(--grey-400);
  box-shadow: 0 -0.5px 0 0 rgba(0,0,0,0.3);
}