.ConfirmText {
  color: var(--grey);
  font-size: 15px;
  line-height: 22px;
  text-align: left;
  margin: 30px 10px 20px;
}

.ConfirmPrompt {
  color: var(--grey);
  font-size: 15px;
  line-height: 22px;
  text-align: left;
  margin: 30px 10px 20px;
}

.ButtonRow {
  display: flex;
  justify-content: flex-end;
}

.OkButton {
  margin: 5px;
  font-weight: bold;
  width: 50px;
}

.CancelButton {
  margin: 5px;
  width: 50px;
}
