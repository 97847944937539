@import './../../../../variables.module.css';

.Item {
    height: 60px;

    box-shadow: inset 0 -1px 0 0 rgba(202,207,214,0.5), 0 -1px 0 0 rgba(202,207,214,0.5);


    color: var(--grey);
    font-size: 15px;
    line-height: 60px;
}

.Content {
    margin: 0 30px;

    position: relative;
}

.Selected {
    color: var(--black);
}

.Check {
    position: absolute;
    right: 0;
    top: 5px
}

.LasOne {
    margin-bottom: 80px;
}
