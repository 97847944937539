.Page {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}