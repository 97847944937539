.Exercise {
    /*display: flex;*/
    margin: 10px 10px 10px 10px;
    border-radius: 6px;
    padding: 15px 20px 20px 20px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.3);
    background-color: #FFFFFF;
    box-sizing: border-box;
}

.ExerciseItem {
    display: flex;
}

.LastOne {
    /*display: flex;*/
    margin: 10px 10px 80px 10px;
    border-radius: 6px;
    padding: 15px 20px 20px 20px;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.3);
    background-color: #FFFFFF;
    box-sizing: border-box;
}

.Title {
    color: #DB4453;
    font-size: 15px;
    line-height: 23px;
    font-weight: bold;
    text-transform: uppercase;
}

.TitleBlue {
    color: var(--blue);
    font-size: 15px;
    line-height: 23px;
    font-weight: bold;
    text-transform: uppercase;
}

.Actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Edit {
    height: 19px;
    color: #7C8796;
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
    margin-left: 20px;
}

.Save {
    height: 19px;
    color: #8dc153;
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
    margin-left: 20px;
}

.Icon {
    padding: 9px 11px 0 0;
}

.Head {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 19px;
}

.HelpButton {
    margin-top: 10px;
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
}

.HelpButton:first-child {
    padding-right: 10px;
}
.HelpButton:last-child {
    padding-left: 10px;
}