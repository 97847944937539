@import './../../../variables.module.css';

.Button {
  display: inline-block;
  box-sizing: border-box;
  background-color: transparent;
  color: var(--white);
  outline: none;
  cursor: pointer;
  font-size: 5vw;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  text-align: center;
  width: 100%;
  height: 70px;
  line-height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 360px) {
  .Button {
     font-size: 18px;
  }
}

.Disabled {
  color: #ccc;
  background-color: #eef1f7 !important;
  cursor: not-allowed;
}

.DisabledWhite {
  color: #ccc;
  background-color: var(--grey-300) !important;
  cursor: not-allowed;
}

.Success {
  background-color: #8dc153;
}

.Red {
  background-color: #db4453;
}

.blue {
  background-color: #1ba4de;
}

.EmptyBlack {
  border: 1px solid #cacfd6;
  background-color: #ffffff;
  height: 100%;
  min-height: 50px;
  line-height: 27px;
  color: #000000;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
}

.RedSlim {
  background-color: #db4453;
  max-height: 50px;
  line-height: 50px;
  text-transform: uppercase;
}

.Silver {
  border: 1px solid #cacfd6;
  background-color: #ffffff;
  min-height: 30px;
  line-height: 38px;
  color: #7c8796;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.SilverFull {
  border: 1px solid #cacfd6;
  background-color: #ffffff;
  height: 100%;
  min-height: 30px;
  line-height: 38px;
  color: #7c8796;
  font-size: 12px;
  text-align: center;
  font-weight: normal;
  text-transform: uppercase;
  padding: 3px;
}

.SilverBig {
  min-height: 50px;
  height: 50px;
  border: 1px solid #cacfd6;
  background-color: #ffffff;
  color: #7c8796;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
}

.SilverMedium {
  min-height: 40px;
  height: 40px;
  border: 1px solid #cacfd6;
  background-color: #ffffff;
  color: #7c8796;  
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
}

.SilverSlim {
  min-height: 30px;
  height: 30px;
  border: 1px solid #cacfd6;
  background-color: #ffffff;
  color: #7c8796;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
}

.Green {
  background-color: #8dc153;
}

.Yellow {
  background-color: #f6bb43;
}

.White {
  color: #000000;
  background-color: #ffffff;
  border-top: 1px solid #cacfd6;
}

.WhiteMini {
  color: #000000;
  background-color: #ffffff;

  font-size: 18px;
  line-height: 27px;
  width: 200px;
  border: 1px solid #cacfd6;
  margin: 5px auto;
  text-transform: uppercase;
}
