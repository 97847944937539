@font-face {
  font-family: 'Pepi';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Pepi-Regular.otf');
}

@font-face {
  font-family: 'Pepi';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Pepi-Bold.otf');
}

@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/bebas-neue-regular.otf'); /* IE9 Compat Modes */
  src: url('./assets/fonts/bebas-neue-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/pt-serif-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

a {
  color: red;
}

input {
  font-family: 'Pepi';
  color: #707372;
}

select {
  font-family: 'Pepi';
  color: #707372;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Pepi', sans-serif;
  font-size: 20px;

  width: 100%;
  background: #fff;
  overflow: hidden;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;

  user-select: none;
}

:global(#root),
body,
html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

body,
html {
  position: fixed;
  overflow: hidden;
}

:global(#root) {
  position: relative;
  width: 100vw;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

/*
@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation: landscape) {
    :global(#landscape_deny) {
        display: block !important;
        height: 100%;
        position: relative;
        width: 100%;
        box-sizing: border-box;
    }

    :global(#landscape_deny) h1 {
        text-align: center;
        color: red;
        margin: 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
    }

    :global(#root) {
        display: none;
    }
}
*/
