:root {
  --red: #DB4453;
  --blue: #1BA4DE;
  --grey: #707372;
  --grey-light: #EEF1F7;

  --black: #000000;
  --white: #FFFFFF;

  --grey-100: #EEF1F7;
  --grey-200: #F9F9F9;
  --grey-300: #F8F9FC;
  --grey-400: rgba(248,248,248,0.82);
  --grey-500: #7C8796;
  --grey-shadow: rgba(142,142,147,0.1);
}
