.title {
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  text-transform: uppercase;
  margin: 30px;
}

.subtitle {
  margin-top: 20px;
  margin-bottom: 30px;
  color: var(--grey);
  font-size: 15px;
  line-height: 22px;
  text-align: center;
}
